import { useCallback } from 'react';
import type { ApolloError } from 'apollo-client';

import { fg } from '@confluence/feature-gating';
import {
	useGetOnboardingState,
	deserializeState,
	useSetOnboardingState,
} from '@confluence/onboarding-helpers/entry-points/hooks/useOnboardingState';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { useIsCurrentPageLive } from '@confluence/live-pages-utils/entry-points/useIsCurrentPageLive';
import { useObjectSidebarState } from '@confluence/object-sidebar-api';
import { SPACE_OVERVIEW } from '@confluence/named-routes';
import { useRouteName } from '@confluence/route-manager';

import { LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS } from '../constants/onboardingStateKeys';

import { useIsLivePagesAvailableInSpace } from './useIsLivePagesAvailableInSpace';

type LiveDocChangeboarding = Record<
	'introSpotlight' | 'liveDocTour' | 'contentWrapperTour',
	{
		isVisible: boolean;
		complete: () => void;
	}
> & {
	error?: ApolloError;
};

const noChangeboarding: LiveDocChangeboarding = {
	introSpotlight: {
		isVisible: false,
		complete: () => {},
	},
	liveDocTour: {
		isVisible: false,
		complete: () => {},
	},
	contentWrapperTour: {
		isVisible: false,
		complete: () => {},
	},
};

const IS_ON_SPACE_OVERVIEW_ROUTES_ARGS = {
	selector: (routeName: string | undefined) => routeName === SPACE_OVERVIEW.name,
};

export const useLiveDocChangeboarding: () => LiveDocChangeboarding = () => {
	const { setOnboardingState } = useSetOnboardingState();

	const { data, loading, error } = useGetOnboardingState(
		[...Object.values(LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS)],
		!fg('cc_onboarding_changeboarding_for_live_pages_eap') &&
			!fg('confluence_frontend_object_sidebar_changeboarding'),
	);
	const { hasDismissedIntroSpotlight, hasDismissedLiveDocTour, hasDismissedContentWrapperTour } =
		deserializeState(data);

	const { isTwoPageTypesExperience } = useIsLivePagesFeatureEnabled();
	const isLivePagesAvailableInSpace = useIsLivePagesAvailableInSpace();
	const isLivePage = useIsCurrentPageLive();
	const { sidebarControlState } = useObjectSidebarState();
	const isContentWrapperVisible = sidebarControlState === 'shown';

	const isOnSpaceOverviewRoute = useRouteName(IS_ON_SPACE_OVERVIEW_ROUTES_ARGS);

	const completeIntroSpotlight = useCallback(() => {
		setOnboardingState({
			key: LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS.hasDismissedIntroSpotlight,
			value: 'true',
		});
	}, [setOnboardingState]);

	const completeLiveDocTour = useCallback(() => {
		setOnboardingState({
			key: LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS.hasDismissedLiveDocTour,
			value: 'true',
		});
	}, [setOnboardingState]);

	const completeContentWrapperTour = useCallback(() => {
		setOnboardingState({
			key: LIVE_DOC_CHANGEBOARDING_ONBOARDING_STATE_KEYS.hasDismissedContentWrapperTour,
			value: 'true',
		});
	}, [setOnboardingState]);

	if (error) {
		return {
			...noChangeboarding,
			error,
		};
	}
	if (loading) {
		return noChangeboarding;
	}

	const showIntroSpotlight =
		isTwoPageTypesExperience &&
		!hasDismissedIntroSpotlight &&
		isLivePagesAvailableInSpace &&
		!isLivePage &&
		fg('cc_onboarding_changeboarding_for_live_pages_eap');

	const showLiveDocTour =
		isLivePage &&
		isTwoPageTypesExperience &&
		!hasDismissedLiveDocTour &&
		fg('cc_onboarding_changeboarding_for_live_pages_eap');

	const showContentWrapperTour =
		!isOnSpaceOverviewRoute &&
		isContentWrapperVisible &&
		!hasDismissedContentWrapperTour &&
		fg('confluence_frontend_object_sidebar_changeboarding');

	if (showLiveDocTour) {
		return {
			...noChangeboarding,
			liveDocTour: {
				isVisible: true,
				complete: completeLiveDocTour,
			},
		};
	}

	if (showContentWrapperTour) {
		return {
			...noChangeboarding,
			contentWrapperTour: {
				isVisible: true,
				complete: completeContentWrapperTour,
			},
		};
	}

	if (showIntroSpotlight) {
		return {
			...noChangeboarding,
			introSpotlight: {
				isVisible: true,
				complete: completeIntroSpotlight,
			},
		};
	}

	return noChangeboarding;
};
